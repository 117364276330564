import React from 'react';
import $ from 'jquery';

window.jQuery = $;
window.$ = $;
global.jQuery = $;

const HomeView = React.lazy(() => import('./pages/Home/homeView'));
const DemoHomeView = React.lazy(() => import('./pages/Home/demoHomeView'));

/* Start Actor */

const ActorListView = React.lazy(() => import('./pages/Actor/allActorsView'));
const ActorView = React.lazy(() => import('./pages/Actor/actorView'));
const AddActorView = React.lazy(() => import('./pages/Actor/addAndEditActorView'));
const ModifyActorView = React.lazy(() => import('./pages/Actor/addAndEditActorView'));
const ActorListViewFilterAQL = React.lazy(() => import('./pages/Actor/allActorsView'));
const ActorPrintView = React.lazy(() => import('./pages/Actor/actorPrintView'));

/* Finish Actor */

/* Start Event */

const EventListView = React.lazy(() => import('./pages/Event/allEventsView'));
const EventView = React.lazy(() => import('./pages/Event/eventView'));
const AddEventView = React.lazy(() => import('./pages/Event/addAndEditEventView'));
const ModifyEventView = React.lazy(() => import('./pages/Event/addAndEditEventView'));
const EventListViewFilterAQL = React.lazy(() => import('./pages/Event/allEventsView'));
const EventPrintView = React.lazy(() => import('./pages/Event/eventPrintView'));

/* Finish Event */

/* Start Indicator */

const IndicatorListView = React.lazy(() => import('./pages/Indicator/allIndicatorsView'));

/* Finish Indicator */

/* Start Tool */

const ToolListView = React.lazy(() => import('./pages/Tool/allToolsView'));
const ToolView = React.lazy(() => import('./pages/Tool/toolView'));
const AddToolView = React.lazy(() => import('./pages/Tool/addAndEditToolView'));
const ModifyToolView = React.lazy(() => import('./pages/Tool/addAndEditToolView'));
const ToolListViewFilterAQL = React.lazy(() => import('./pages/Tool/allToolsView'));
const ToolPrintView = React.lazy(() => import('./pages/Tool/toolPrintView'));

/* Finish Tool */

/* Start TTP */

const TTPListView = React.lazy(() => import('./pages/TTP/allTTPsView'));

/* Finish TTP */

/* Start Quality Check */

const QualityCheckView = React.lazy(() => import('./pages/QualityCheck/qualityCheckView'));

/* Finish Quality Check */

/* Start Activate User */

const ActivateUserView = React.lazy(() => import('./pages/activateUserView'));

/* Finish Activate User */

/* Start CVEs */

const AllCVEsView = React.lazy(() => import('./pages/CVE/allCVEsView'));

/* Finish CVEs */

/* Start ARTIC Sage */

const ARTICSageView = React.lazy(() => import('./pages/ARTICSage/articSageView'));

/* Finish ARTIC Sage */

const routes = [
    { path: '/index', exact: true, name: 'Home', component: HomeView },
    { path: '/demoHome', exact: true, name: 'Demo Home', component: DemoHomeView },

    { path: '/actor/all', exact: true, name: 'All Actors', component: ActorListView },
    { path: '/actor/new', exact: true, name: 'New Actor View', component: AddActorView},
    { path: '/actor/view/:id', exact: true, name: 'Actor View', component: ActorView},
    { path: '/actor/modify/:id', exact: true, name: 'Modify Actor View', component: ModifyActorView},
    { path: '/actor/allFilterAQL/:type/:data', exact: true, name: 'All Actors Filter AQL', component: ActorListViewFilterAQL},
    { path: '/actor/allFilterAQL/:type1/:data1/:op/:type2/:data2', exact: true, name: 'All Actors Filter AQL', component: ActorListViewFilterAQL},
    { path: '/actor/print/:id', exact: true, name: 'Print Actor information', component: ActorPrintView},

    { path: '/event/all', exact: true, name: 'All Events', component: EventListView },
    { path: '/event/allFilterAQL/:type/:data', exact: true, name: 'All Events Filter AQL', component: EventListViewFilterAQL},
    { path: '/event/allFilterAQL/:type1/:data1/:op/:type2/:data2', exact: true, name: 'All Events Filter AQL', component: EventListViewFilterAQL},
    { path: '/event/new', exact: true, name: 'New Event View', component: AddEventView},
    { path: '/event/view/:id', exact: true, name: 'Event View', component: EventView},
    { path: '/event/modify/:id', exact: true, name: 'Modify Event View', component: ModifyEventView},
    { path: '/event/print/:id', exact: true, name: 'Print Event information', component: EventPrintView},

    { path: '/indicator/all', exact: true, name: 'All indicators', component: IndicatorListView},

    { path: '/tool/all', exact: true, name: 'All Tools', component: ToolListView },
    { path: '/tool/new', exact: true, name: 'New Tool', component: AddToolView },
    { path: '/tool/view/:id', exact: true, name: 'Tool view', component: ToolView},
    { path: '/tool/modify/:id', exact: true, name: 'Modify Tool View', component: ModifyToolView},
    { path: '/tool/allFilterAQL/:type/:data', exact: true, name: 'All Tools Filter AQL', component: ToolListViewFilterAQL},
    { path: '/tool/print/:id', exact: true, name: 'Print Tool information', component: ToolPrintView},

    { path: '/TTP/all', exact: true, name: 'All TTPs', component: TTPListView },

    { path: '/qualityCheck/all', exact: true, name: 'Quality Check View', component: QualityCheckView},

    { path: '/activateUser', exact: true, name: 'Activate User View', component: ActivateUserView},

    { path: '/CVE/all', exact: true, name: 'All CVEs', component: AllCVEsView},

    { path: '/articsage', exact: true, name: 'ARTIC Sage', component: ARTICSageView},
];

export default routes;