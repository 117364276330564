/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
    items: [
        {
            id: 'pages',
            type: 'group',
            icon: 'icon-pages',
            children: [
                {
                    id: 'home',
                    title: 'Dashboard',
                    type: 'item',
                    url: '/index',
                    classes: 'nav-item',
                    icon: 'fa fa-tachometer'
                },
                {
                    id: 'actor',
                    title: 'Actors',
                    type: 'collapse',
                    classes: 'nav-item',
                    icon: 'fa fa-users',
                    children: [
                        {
                            id: 'all-actors',
                            title: 'All actors',
                            type: 'item',
                            url: '/actor/all'
                        },
                        {
                            id: 'new-actor',
                            title: 'New actor',
                            type: 'item',
                            url: '/actor/new'
                        },
                    ]
                },
                {
                    id: 'event',
                    title: 'Events',
                    type: 'collapse',
                    classes: 'nav-item',
                    icon: 'fa fa-calendar',
                    children: [
                        {
                            id: 'all-events',
                            title: 'All events',
                            type: 'item',
                            url: '/event/all'
                        },
                        {
                            id: 'new-event',
                            title: 'New event',
                            type: 'item',
                            url: '/event/new'
                        },
                    ]
                },
                {
                    id: 'tool',
                    title: 'Tools',
                    type: 'collapse',
                    classes: 'nav-item',
                    icon: 'fa fa-cogs',
                    children: [
                        {
                            id: 'all-tools',
                            title: 'All tools',
                            type: 'item',
                            url: '/tool/all'
                        },
                        {
                            id: 'new-tool',
                            title: 'New tool',
                            type: 'item',
                            url: '/tool/new'
                        },
                    ]
                },
                {
                    id: 'indicator',
                    title: 'Indicator',
                    type: 'item',
                    classes: 'nav-item',
                    icon: 'fa fa-bookmark',
                    url:'/indicator/all',
                },
                {
                    id: 'ttp',
                    title: 'TTP',
                    type: 'item',
                    classes: 'nav-item',
                    icon: 'fa fa-superpowers',
                    url: '/ttp/all',
                },
                {
                    id: 'cves',
                    title: 'CVEs',
                    type: 'item',
                    classes: 'nav-item',
                    icon: 'fa fa-bullseye',
                    url: '/cve/all',
                },
                {
                    id: 'qualityCheck',
                    title: 'Quality Check',
                    type: 'item',
                    classes: 'nav-item',
                    icon: 'fa fa-check-circle-o ',
                    url: '/qualityCheck/all',
                },
                {
                    id: 'articSage',
                    title: 'ARTIC Sage',
                    type: 'item',
                    classes: 'nav-item',
                    icon: 'fa fa-user-circle-o ',
                    url: '/articsage',
                }
            ]
        }
    ]
}