import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Dropdown} from 'react-bootstrap';
import windowSize from 'react-window-size';
import {FaUserCheck, FaUserPlus, FaUserShield} from "react-icons/fa";
import Aux from "../../../../../hoc/_Aux";
import * as actionTypes from "../../../../../store/actions";
import config from '../../../../../config';
const token = config.token;
const urlAPI = config.urlAPI;

class NavLeft extends Component {
    constructor(props)
    {
        super(props);

        this.state = {
            loggedIn:true,
            hasCookie:'',
            superuser:false, 
            is_staff:false,
            can_manage_users:false, 
        }
        this.getData('logged_user/', 'Your session expired. You will be redirected to our login page.');

    }

    /*
    * Fetchs data from API
    * #param  {String, String, String, Boolean} type, url, msgError, pagination -> object from which data is fetched, url for fetch, message error to show if fetch throws error, true if fetch has pagination false if doesn't   
    * #return  no return, it sets results to variables
    */
    getData = ( url, msgError) => {

        var requestOptions = {};
        if(token){
            requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', 'Authorization' : token },
            };
        }
        else{
            requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json'},
            };
        }

        fetch(urlAPI + url, requestOptions)
        .then(response => {
            
            if (!response.ok) throw Error(response.json().detail);

            return response.json();

        }).then(resp => {
            this.setState(prevState => ({
                superuser: resp.is_superuser,
                is_staff: resp.is_staff,
                can_manage_users: resp.can_manage_users,
            }));
        })
        .catch(errorMsg => {
            alert(msgError);
        });

    };


    render() {

        let navItemClass = ['nav-item'];
        if (this.props.windowWidth <= 575) {
            navItemClass = [...navItemClass, 'd-none'];
        } 
        let dropdownRightAlign = false;
        if (this.props.rtlLayout) {
            dropdownRightAlign = true;
        } 
 

        return (
            <Aux>
                {/*-------------------------------------------------------- Start Admin Options --------------------------------------------------------*/}
                <ul className="navbar-nav">
                    {((this.state.can_manage_users||this.state.superuser)||this.state.is_staff) &&
                    <li className={navItemClass.join(' ')}>
                        <Dropdown alignRight={dropdownRightAlign} >
                            <Dropdown.Toggle variant={'link'} id="dropdown-basic" title='Admin options'>
                                <FaUserShield title='Admin options' size='20px' className='mb-1'/>
                            </Dropdown.Toggle>
                            <ul >
                                <Dropdown.Menu >
                                    {(this.state.can_manage_users||this.state.superuser) && <li ><a href={'/activateUser'} target="_blank" rel="noopener noreferrer" title="Activate user" style={{textDecoration:"none"}}><FaUserCheck className='mr-2' style={{marginBottom:'1px'}}/> Activate user</a></li>}
                                    {(this.state.is_staff && (this.state.can_manage_users||this.state.superuser)) && <hr/>}
                                    {this.state.is_staff && <li ><a href={urlAPI.substring(0, urlAPI.length - 7) + "users/admin"} target="_blank" rel="noopener noreferrer" title="Admin panel" style={{textDecoration:"none"}}><FaUserShield className='mr-2' style={{marginBottom:'1.5px'}}/> Admin panel</a></li>}
                                    {this.state.is_staff && <hr/>}
                                    {this.state.is_staff && <li ><a href={urlAPI.substring(0, urlAPI.length - 7) + "users/admin/users/add"} target="_blank" rel="noopener noreferrer" title="Create user" style={{textDecoration:"none"}}><FaUserPlus className='mr-2' style={{marginBottom:'1.5px'}}/> Create user</a></li>}
                                    {this.state.is_staff && <hr/>}
                                    {this.state.is_staff && <li ><a href={urlAPI.substring(0, urlAPI.length - 7) + "users/admin/users/activate"} target="_blank" rel="noopener noreferrer" title="Activate user via admin panel" style={{textDecoration:"none"}}> <FaUserCheck className='mr-2' style={{marginBottom:'2px'}}/> Activate user panel</a></li>}
                                </Dropdown.Menu>
                            </ul>
                        </Dropdown>
                    </li> }
                </ul>
                {/*-------------------------------------------------------- End Admin Options --------------------------------------------------------*/}
            </Aux>
        );
    }
}

const mapStateToProps = state => {
    return {
        isFullScreen: state.isFullScreen,
        rtlLayout: state.rtlLayout
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onFullScreen: () => dispatch({type: actionTypes.FULL_SCREEN}),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(windowSize(NavLeft));
