import React, { Component, Suspense } from 'react';
import { Switch, Route} from 'react-router-dom';
import '../node_modules/font-awesome/scss/font-awesome.scss';
import Aux from "./hoc/_Aux";
import config from './config';
import LoadingPresentation from './assets/images/articLoader.gif';
import AdminLayout from './components/Layout/AdminLayout';
import InactiveUser from './components/Generics/InactiveUser';

const token = config.token;
const urlAPI = config.urlAPI;

class App extends Component {
    
    constructor(props)
    {
        super(props);

        this.state = {
            loggedIn:'',
            is_active:'',
            isLoaded:false,
        }
        this.getData('logged_user/', 'Your session expired. You will be redirected to our login page.');
    }

    componentDidMount(){
        if(window.location.href === urlAPI.substring(0, urlAPI.length - 7)){
            setTimeout(() => {
            this.setState({isLoaded:true});
            },3000);
        }
        else{
            this.setState({isLoaded:true});
        }
    }

    /*
    * Fetchs data from API
    * #param  {String, String, String, Boolean} type, url, msgError, pagination -> object from which data is fetched, url for fetch, message error to show if fetch throws error, true if fetch has pagination false if doesn't   
    * #return  no return, it sets results to variables
    */
    getData = ( url, msgError) => {

        var requestOptions = {};
        if(token){
            requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', 'Authorization' : token },
            };
        }
        else{
            requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json'},
            };
        }
        let responseStatus='';
        fetch(urlAPI + url, requestOptions)
        .then(response => {
            responseStatus=response.status;
            if (!response.ok) throw Error(response.json().detail);
            return response.json();

        }).then(resp => {
            this.setState(prevState => ({
                is_active:resp.is_active,
                loggedIn:true,
            }));

        })
        .catch(errorMsg => {
            if(responseStatus===403){
                this.setState(prevState=> ({
                    is_active:false,
                    loggedIn:true,
                }));
            }
            else if(responseStatus===401){
                this.setState(prevState=> ({
                    is_active:null,
                    loggedIn:false,
                }));
                alert(msgError);
            }
        });
    };


    render() {
        let urlLogin = urlAPI + 'login';
        let currentUrl = window.location.href

        if(!this.state.isLoaded){ 
            return  <div style={{backgroundColor:'#1b1c1f', justifyContent:'center'}}>
                    <div style={{height:"100%", paddingBottom:"25%",  textAlign:'center', paddingTop:'10%'}} >
                        <img src={LoadingPresentation} style={{alignContent:'center',}} alt="loading..." />
                    </div>
                </div>
        } 
        else{ 
            return (
                <Aux>
                    <Suspense fallback={<LoadingPresentation/>}>
                        {/*-------------------------------------------------------- Start Navigation --------------------------------------------------------*/}
                        <Switch>
                            {/*-------- User is not logged in ---------*/}
                            {(!this.state.loggedIn && (this.state.is_active===true || this.state.is_active===false || this.state.is_active===null )) &&
                                <Route path="/" component={()=> {return window.location.href= urlLogin + "?next=" + currentUrl}}> </Route>
                            }
                            {/*-------- User is logged but Inactive ---------*/}
                            {(this.state.loggedIn && !this.state.is_active) &&
                                <Route path="/" component={InactiveUser}> </Route>                             
                            }
                            {/*-------- User is logged in ---------*/}
                            {(this.state.loggedIn && this.state.is_active) &&
                                <Route path="/" component={AdminLayout}> </Route>
                            }
                        </Switch>
                        {/*-------------------------------------------------------- Start Navigation --------------------------------------------------------*/}
                    </Suspense>
                </Aux>
            );
        } 
    }
}

export default App;
