import React from 'react';
import CONSTANTS  from '../../../../../store/constant';
import Aux from "../../../../../hoc/_Aux";
import Logo from "../../../../../assets/images/logo-artic.png";


const navLogo = (props) => {
    
    let toggleClass = ['mobile-menu'];
    
    if (props.collapseMenu) {
        toggleClass = [...toggleClass, 'on'];
    }

    return (
        <Aux>
            <div className="navbar-brand header-logo">
                 <a href={'/index'} className="b-brand" style={{textDecoration:"none"}}>
                    <div>
                        <img src={Logo} alt="Logo Artic" style={{ width: '60px', heigth: 'auto',}}/>
                    </div>
                    <span className="b-title">ARTIC</span>
                 </a>
                <a href={CONSTANTS.BLANK_LINK} className={toggleClass.join(' ')} id="mobile-collapse" onClick={props.onToggleNavigation}><span /></a>
            </div>
        </Aux>
    );
};

export default navLogo;
