import React, { Component, Suspense } from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import windowSize from 'react-window-size';
import {Modal, Button, Row, Col} from 'react-bootstrap';
import Navigation from './Navigation';
import NavBar from './NavBar';
import Breadcrumb from './Breadcrumb';
import Loader from "../Loader";
import routes from "../../../routes";
import Aux from "../../../hoc/_Aux";
import navigation from '../../../menu-items';
import * as actionTypes from "../../../store/actions";
import './app.scss';
import ArticPresentation from "../../../assets/images/articLogoPresentationSmall.gif";
import config from "../../../config";
import { IoHeart } from "react-icons/io5";
import { BsFillEmojiHeartEyesFill } from "react-icons/bs";
import Konami from '../../../assets/images/tooEasy.gif';

const token = config.token;
const urlAPI = config.urlAPI;
const Mousetrap = require('mousetrap');

class AdminLayout extends Component {
    constructor(props)
    {
        super(props);

        this.state = {
            superuser:false, //set super user
            can_edit:false, //set can edit
            can_use_qualitycheck:false, //set use qualitycheck
            can_use_artic_sage:false, //set use can_use_artic_sage
            navigationItems:navigation.items, 
            navLoaded:false,
            articKeys:false,
            konamiKeys:false,
            avoidShowingMenuNavbar: false,
        }
        this.getData('logged_user/', 'Error, fetching user permissions.');
        this.handleCloseModalArticPresentation = this.handleCloseModalArticPresentation.bind(this);
        this.handleOpenModalArticPresentation = this.handleOpenModalArticPresentation.bind(this);
        this.handleCloseModalKonami = this.handleCloseModalKonami.bind(this);
        this.handleOpenModalKonami = this.handleOpenModalKonami.bind(this);

    }

    componentDidMount() {
        let _this = this;
        Mousetrap.bind('up up down down left right left right b a enter', function() {
            _this.setState({
                konamiKeys: true,
            });
        });
        Mousetrap.bind('a r t i c enter', function() {
            _this.setState({
                articKeys: true,
            });
        });

        if(window.location.href.includes('/print/')){
            _this.setState({
                avoidShowingMenuNavbar: true,
            });
            document.body.classList.add('datta-dark');
        }
    }
   
    componentWillMount() {
        if (this.props.windowWidth > 992 && this.props.windowWidth <= 1024 && this.props.layout !== 'horizontal') {
            this.props.onComponentWillMount();
        }

    }

    componentWillUnmount() {
        Mousetrap.unbind('up up down down left right left right b a enter');
        Mousetrap.unbind('a r t i c enter');
    }

    mobileOutClickHandler() {
        if (this.props.windowWidth < 992 && this.props.collapseMenu) {
            this.props.onComponentWillMount();
        }
    }

    /*
     * Fetchs data from API
     * #param  {String, String, String, Boolean} type, url, msgError, pagination -> object from which data is fetched, url for fetch, message error to show if fetch throws error, true if fetch has pagination false if doesn't   
     * #return  no return, it sets results to variables
    */
    getData = ( url, msgError) => {

        var requestOptions = {};
        if(token){
            requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', 'Authorization' : token },
            };
        }
        else{
            requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json'},
            };
        }

        fetch(urlAPI + url, requestOptions)
        .then(response => {
            
            if (!response.ok) throw Error(response.json().detail);


            return response.json();

        }).then(resp => {
            this.setState(prevState => ({
                superuser: resp.is_superuser,
                can_edit:resp.can_edit,
                can_use_qualitycheck:resp.can_use_quality_check,
                can_use_artic_sage:resp.can_use_artic_sage,
                
            }));
            this.setNavigationItems(navigation.items);


        })
        .catch(errorMsg => {
        });

    };

    /*
     * Populates the menu with the corresponding buttons depending on permissions
     * #param {Object} navigationItems -> navigation items
     * #return  none
    */
    setNavigationItems(navigationItems){
        
        var auxArrayItems = navigationItems;
        if(!this.state.superuser){
            for(let i=0;i<auxArrayItems[0].children.length;i++){
                if(!this.state.can_edit){
                   if(auxArrayItems[0].children[i].id==='actor' || auxArrayItems[0].children[i].id==='event' ||auxArrayItems[0].children[i].id==='tool' ){
                        auxArrayItems[0].children[i].children.splice(1,1);
                   }
                }
                if(!this.state.can_use_qualitycheck){
                   if(auxArrayItems[0].children[i].id==='qualityCheck'){
                        auxArrayItems[0].children.splice(i,1);
                   }
                }
                if(!this.state.can_use_artic_sage){
                   if(auxArrayItems[0].children[i].id==='articSage'){
                        auxArrayItems[0].children.splice(i,1);
                   }
                }
            }
            this.setState(prevState => ({
                navLoaded:true,
            }));
        }
        else{
            auxArrayItems=navigationItems;
            this.setState(prevState => ({
                navLoaded:true, 
            }));
        }
   
    }
    
    /*
     * Handles closing of current modal 
     * #param   
     * #return  none
    */
    handleCloseModalArticPresentation()
    {
        this.setState(prevState => ({
            articKeys: false,
        }));

    }

    /*
    * Handles opening of current modal 
    * #param   
    * #return  none
    */
    handleOpenModalArticPresentation()
    {
        this.setState(prevState => ({
            articKeys: true
        }));
    } 
    /*
     * Handles closing of current modal 
     * #param   
     * #return  none
    */
    handleCloseModalKonami()
    {
        this.setState(prevState => ({
            konamiKeys: false,
        }));

    }

    /*
    * Handles opening of current modal 
    * #param   
    * #return  none
    */
    handleOpenModalKonami()
    {
        this.setState(prevState => ({
            konamiKeys: true
        }));
    } 

    render() {

        const routesAccessible = routes.map((route, index) => {
            if(!this.state.superuser){
                if((this.state.can_use_artic_sage || route.path !== '/articsage') && (this.state.can_use_qualitycheck || route.path !== '/qualityCheck/all') && (this.state.can_edit || route.path !== '/actor/new' && route.path !== '/event/new' && route.path !== '/indicator/new' && route.path !== '/indicator/modify/:id' && route.path !== '/tool/new' && route.path !== '/TTP/new' && route.path !== '/TTP/modify/:id')){
                    return (route.component) ? (
                        <Route
                            key={index}
                            path={route.path}
                            exact={route.exact}
                            name={route.name}
                            render={props => (
                                <route.component {...props} />
                            )} />
                    ) : (null);
                }
            }
            else{
                return (route.component) ? (
                    <Route
                        key={index}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        render={props => (
                            <route.component {...props} />
                        )} />
                ) : (null);
            }
            
        });  
        
        
        return (
            <Aux>
                {/*-------------------------------------------------------- Start Easter Egg Modal - Do you like our presentation? --------------------------------------------------------*/}
                <Modal show={this.state.articKeys} onHide={this.handleCloseModalArticPresentation} size="xl">
                    <Modal.Header closeButton style={{backgroundColor:'#1b1c1f'}}>Do you like our presentation?</Modal.Header>
                    <Modal.Body style={{backgroundColor:'#1b1c1f',textAlign:'center'}}>
                        <img src={ArticPresentation} className='mt-5 mb-5' style={{width:'100%', maxWidth:'600px'}}/>
                        <hr/>
                        <Row>
                            <Col md={3}>
                                <Button variant="primary"  onClick={this.handleCloseModalArticPresentation}>That's GOOD!</Button>
                            </Col>
                            <Col md={3}>
                                <Button variant="outline-primary"  onClick={this.handleCloseModalArticPresentation}>Love it <IoHeart/></Button>
                            </Col>
                            <Col md={3}>
                                <Button variant="primary"  onClick={this.handleCloseModalArticPresentation}>Awesome!</Button>
                            </Col>
                            <Col md={3}>
                                <Button variant="outline-primary"  onClick={this.handleCloseModalArticPresentation}>Without doubt <BsFillEmojiHeartEyesFill/></Button>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
                {/*-------------------------------------------------------- End Easter Egg Modal - Do you like our presentation? --------------------------------------------------------*/}
                {/*-------------------------------------------------------- Start Easter Egg Modal - Konami code --------------------------------------------------------*/}
                <Modal show={this.state.konamiKeys} onHide={this.handleCloseModalKonami} size="xl">
                    <Modal.Body>
                        <Row className='mt-5 mb-5' style={{textAlign:'center'}} >
                            <Col xs={12} lg={7}>
                                <Row>
                                    <Col><h1 style={{ fontSize:'90px', fontFamily:'monospace'}}>Come on!</h1></Col>
                                </Row>
                                <Row>
                                    <Col><h1 style={{fontSize:'40px'}}>Try harder, Konami code is an easy one...</h1></Col>
                                </Row>
                                <Row className='mt-4'>
                                    <Col style={{textAlign:'center'}}><Button variant="primary" onClick={this.handleCloseModalKonami}>Keep trying</Button></Col>
                                </Row>
                            </Col>
                            <Col xs={12} lg={5}>
                                <img src={Konami} style={{ width:'100%'}}/>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
                {/*-------------------------------------------------------- End Easter Egg Modal - Konami code --------------------------------------------------------*/}
                {/*-------------------------------------------------------- Start Setting of Page (Menu, NavBar and Routes) --------------------------------------------------------*/}
                {(this.state.navLoaded && !this.state.avoidShowingMenuNavbar) && <Navigation navigation={this.state.navigationItems}/> }
                {(this.state.navLoaded && !this.state.avoidShowingMenuNavbar) && <NavBar />}
                {(this.state.navLoaded && !this.state.avoidShowingMenuNavbar) && <div className="pcoded-main-container" onClick={() => this.mobileOutClickHandler}>
                    <div className="pcoded-wrapper">
                        <div className="pcoded-content">
                            <div className="pcoded-inner-content">
                                <Breadcrumb />
                                <div className="main-body">
                                    <div className="page-wrapper">
                                        <Suspense fallback={<Loader/>}>
                                            <Switch>
                                                {routesAccessible}
                                                
                                                <Redirect from="/" to={this.props.defaultPath} />
                                            </Switch>
                                        </Suspense>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
                {(this.state.navLoaded && this.state.avoidShowingMenuNavbar) && <div className="main-body">
                    <div className="page-wrapper">
                        <Suspense fallback={<Loader/>}>
                            <Switch>
                                {routesAccessible}
                                
                                <Redirect from="/" to={this.props.defaultPath} />
                            </Switch>
                        </Suspense>
                    </div>
                </div>}
                {/*-------------------------------------------------------- End Setting of Page (Menu, NavBar and Routes) --------------------------------------------------------*/}
            </Aux>
        );
    }
}

const mapStateToProps = state => {
    return {
        defaultPath: state.defaultPath,
        isFullScreen: state.isFullScreen,
        collapseMenu: state.collapseMenu,
        configBlock: state.configBlock,
        layout: state.layout
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onFullScreenExit: () => dispatch({type: actionTypes.FULL_SCREEN_EXIT}),
        onComponentWillMount: () => dispatch({type: actionTypes.COLLAPSE_MENU})
    }
};

export default connect(mapStateToProps, mapDispatchToProps) (windowSize(AdminLayout));