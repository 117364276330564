import React from 'react';
import { Row, Col, Card} from 'react-bootstrap';
import Logo from "../../assets/images/logo-artic.png";
import { FaRegSurprise } from "react-icons/fa";

import '../../components/Layout/AdminLayout/app.scss';

class InactiveUser extends React.Component{

    constructor(props) {
        
        super(props);
        
        this.state = {
        };

    }

    render() {
        return (
            <div style={{ backgroundColor:'rgba(0, 0, 0, .9)'}}>
                <div  style={{height:"100%", paddingBottom:"25%"}} >
                    <Row className="mb-4 ml-5">
                        <div className="text-white" style={{fontSize:'70px', paddingTop:"3%"}}>Welcome to </div>
                        <div className="navbar-brand b-brand mr-5" style={{backgroundColor:'rgba(0, 0, 0,.0)', paddingTop:"3%"}}>
                                <div>
                                    <img src={Logo} alt="Logo Artic" style={{ width: '110px', heigth: 'auto',}}/>
                                </div>
                                <span className="b-title" style={{ fontWeight: 'bold!important', fontSize:"60px"}}>ARTIC</span>
                                <div className="text-white ml-3" style={{fontSize:'70px'}}>!</div>
                        </div>
                    </Row>
                    <Row className="mt-5">
                        <Col md={3}></Col>
                        <Col md={6}>
                            <Card className="bg-dark text-white" style={{paddingBottom:'10%'}}>
                                <Card.Body>
                                    <Card.Title style={{paddingBottom:"1%"}}>Your account is inactive  <FaRegSurprise/></Card.Title>
                                    <hr style={{paddingTop:"1%", borderTop:"2px solid white"}}/>
                                    <Card.Text>
                                    Please, send an email to <a href="mailto:lmartins@deloitte.com?subject=Activate Artic Account&body=Hi Luciano, I was wondering if you can activate my Artic Account! Thanks, ">lmartins@deloitte.com</a> so that your account can be activated!
                                    </Card.Text>
                                </Card.Body>      
                            </Card>
                        </Col>
                        <Col md={3}></Col>
                    </Row>
                </div>
            </div>
        );
    }
}


export default InactiveUser;