import React, { Component } from 'react';
import {Dropdown, Modal, Col, Row, Button} from 'react-bootstrap'; 
import Aux from "../../../../../hoc/_Aux";
import config from "../../../../../config";
import {FaKey, FaUserCircle, FaRegQuestionCircle} from "react-icons/fa";
import Avatar from 'react-avatar';
import MailImage from '../../../../../assets/images/correo.png';

const urlAPI = config.urlAPI;
const token = config.token;


class NavRight extends Component {

    constructor(props)
    {
        super(props);

        this.state = {
            listOpen:false,
            name:'',
            surname:false,
            email:'',
            apiKey:'',
            showApiKey:false,
            copyApyKey:false,
            showHelpModal: false,
        }

        this.getData('logged_user/', 'Error, fetching user permissions.');    
    }

    /*
     * Fetchs data from API
     * #param  {String, String, String, Boolean} type, url, msgError, pagination -> object from which data is fetched, url for fetch, message error to show if fetch throws error, true if fetch has pagination false if doesn't   
     * #return  no return, it sets results to variables
    */
    getData = ( url, msgError) => {

        var requestOptions = {};
        if(token){
            requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', 'Authorization' : token },
            };
        }
        else{
            requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json'},
            };
        }

        fetch(urlAPI + url, requestOptions)
        .then(response => {
            
            if (!response.ok) throw Error(response.json().detail);

            return response.json();

        }).then(resp => {
            this.setState(prevState => ({
                name: resp.first_name,
                surname:resp.last_name,
                email:resp.email, 
                apiKey:resp.auth_token,
            }));
        })
        .catch(errorMsg => {
            
        });

    };

    /*
     * Handles the change of the varibale to show token or not
     * #param none
     * #return  none
    */
    setToogle(){
        this.setState(prevState => ({
            showApiKey:!this.state.showApiKey,
        }));
    }

    /*
     * Handles copy token to clipboard 
     * #param {Event} event -> event
     * #return  none
    */
    copyToClipboard(event){
        var el = document.createElement('textarea');
        el.value = this.state.apiKey;
        el.setAttribute('readonly', '');

        el.style.position = 'fixed';
        el.style.top = 0;
        el.style.left = 0;
        el.style.width = '2em';
        el.style.height = '2em';
        el.style.padding = 0;
        el.style.border = 'none';
        el.style.outline = 'none';
        el.style.boxShadow = 'none';
        el.style.background = 'transparent'; 

        
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        
        this.setState({
            copyApyKey:true,
        });
        
        setTimeout(function () {
            this.setState({copyApyKey: false});
        }.bind(this), 3000)
    }

    /*
     * Handles visibility of the token
     * #param none
     * #return  {Component} show -> Component of the token
    */
    getTokenVisible(){
        let show='';
        if(this.state.showApiKey){
            show=<div className='dropdown-item'><FaKey className='mr-2' title='Api Key'/> {this.state.apiKey}<i className="fa fa-eye-slash ml-3" aria-hidden="true" title="Hide Api Key" style={{cursor:'pointer'}} onClick={event=>this.setToogle(event)}></i><i className="fa fa-clone ml-1" aria-hidden="true" title="Copy Api Key" style={{cursor:'pointer'}} onClick={event=>this.copyToClipboard(event)}></i>{this.state.copyApyKey ?  'Copied!' : ''}</div> 
        }
        else{
            show=<div className='dropdown-item'><FaKey className='mr-3' title='Api Key'/>{'*******'}<i className="fa fa-eye ml-3" aria-hidden="true" title="Show Api Key" style={{cursor:'pointer'}} onClick={event=>this.setToogle(event)}></i><i className="fa fa-clone ml-1" aria-hidden="true" title="Copy Api Key" style={{cursor:'pointer'}} onClick={event=>this.copyToClipboard(event)}></i>{this.state.copyApyKey ? 'Copied!' : ''}</div> 
        }
        return show;
    }

    /*
     * Handles openning and closing of Contact information modal
     * #param   
     * #return  none
    */
    handleShowModal(event){
        this.setState({
            showHelpModal:!this.state.showHelpModal,
        });
    }

    render() {

        return (
            <Aux >
                {/*-------------------------------------------------------- Start Help Pannel Modal --------------------------------------------------------*/}
                <Modal show={this.state.showHelpModal} onHide={(event)=>this.handleShowModal(event)} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title as="h1">Help Pannel</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h1 style={{margin:'15px'}}>Documentation of ARTIC: </h1>
                        <Row style={{textAlign:'center', paddingBottom:'15px'}}>
                            <Col xs={12} lg={6}>
                                <Button href={urlAPI.substring(0, urlAPI.length - 7) + "core/documentation/api/v2/"} target="_blank" rel="noopener noreferrer" title="API Docs" style={{textDecoration:"none", color:'white'}}>API documentation</Button>  
                            </Col>
                            <Col xs={12} lg={6}>
                                <Button href={urlAPI.substring(0, urlAPI.length - 7) + "users/documentation/api/v2/"} target="_blank" rel="noopener noreferrer" title="Users Docs" style={{textDecoration:"none", color:'white'}}>Users documentation</Button>
                            </Col>
                        </Row>
                        <hr style={{paddingBottom:'15px'}}/>
                        <Row style={{textAlign:'center', justifyContent:'center', paddingBottom:'15px'}} >
                            <Col xs={12} lg={7} style={{}}>
                                <h1 >Did you find a bug or just want to contact us?</h1>
                                <a href="mailto:arraartic@deloitte.com" style={{color:'#007BFF', fontSize:'30px'}}>arraartic@deloitte.com</a> 
                            </Col>
                            <Col xs={12} lg={'auto'}>
                                <img src={MailImage} style={{width:'90px'}} className='ml-0 ml-lg-4 mt-3 mt-lg-0'/>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
                {/*-------------------------------------------------------- End Help Pannel Modal --------------------------------------------------------*/}
                <ul className="navbar-nav ml-auto" >
                    <li>
                        {/*-------------------------------------------------------- Start User Pannel --------------------------------------------------------*/}
                        <Dropdown alignRight={!this.props.rtlLayout} className="drp-user">
                            <Dropdown.Toggle variant={'link'} id="dropdown-basic" style={{marginRight:'-5px', marginTop:'-5px'}}>
                                <FaUserCircle size='25px' title='User information'/>
                            </Dropdown.Toggle>
                            <Dropdown.Menu alignRight className="profile-notification">
                                <div className="pro-head">
                                    <Avatar name={this.state.name+' '+this.state.surname} size='40' className='img-radius'/>
                                    <span className='ml-2'>{this.state.name + ' ' + this.state.surname}</span>
                                    <a href={urlAPI.substring(0, urlAPI.length - 7) + "api/v2/logout"} target="_blank" rel="noopener noreferrer" className="dud-logout" title="Logout">
                                        <i className="feather icon-log-out mr-2"/>
                                    </a>
                                </div>
                                <ul className="pro-body">
                                    <li><div className='dropdown-item'><i className="feather icon-mail" title='Email'/> {this.state.email}</div></li>
                                    <li>{this.getTokenVisible()}</li>
                                </ul>
                            </Dropdown.Menu>
                        </Dropdown>
                        {/*-------------------------------------------------------- End User Pannel --------------------------------------------------------*/}
                    </li>
                    <li>
                        {/*-------------------------------------------------------- Start Button Trigger Help Pannel --------------------------------------------------------*/}
                        <div onClick={(event) => this.handleShowModal(event)}>
                            <FaRegQuestionCircle size='25px' title='Help pannel'/>
                        </div>
                        {/*-------------------------------------------------------- End Button Trigger Help Pannel --------------------------------------------------------*/}
                    </li>
                </ul>
            </Aux>
        );
    }
}

export default NavRight;
