import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import config from '../../../../config';
import navigation from '../../../../menu-items';
import CONSTANTS from "../../../../store/constant";
import Aux from "../../../../hoc/_Aux";

class Breadcrumb extends Component {
    state = {
        main: [],
        item: []
    };

    componentDidMount() {
        (navigation.items).map((item, index) => {
            if (item.type && item.type === 'group') {
                this.getCollapse(item, index);
            }
            return false;
        });
    }

    componentWillReceiveProps = () => {
        (navigation.items).map((item, index) => {
            if (item.type && item.type === 'group') {
                this.getCollapse(item);
            }
            return false;
        });
    };

    getCollapse = (item) => {
        if (item.children) {
            (item.children).filter( collapse => {
                if (collapse.type && collapse.type === 'collapse') {
                    this.getCollapse(collapse,);
                } else if (collapse.type && collapse.type === 'item') {
                    if (document.location.pathname === config.basename+collapse.url) {
                        this.setState({item: collapse, main: item});
                    }
                }
                return false;
            });
        }
    };

    render() {
        let main, item;
        let breadcrumb = '';
        let title = '';
        if (this.state.main && this.state.main.type === 'collapse') {
            title = this.state.item.title;
            main = (
                <li className="breadcrumb-item py-3">
                    <a href={CONSTANTS.BLANK_LINK} style={{textDecoration:"none", fontSize:'16px'}}>{title}</a>
                    
                </li>
            );
        }

        if (this.state.item && this.state.item.type === 'item') {
            title = this.state.item.title;
            item = (
                <li className="breadcrumb-item py-3">
                    <a href={CONSTANTS.BLANK_LINK} style={{textDecoration:"none", fontSize:'16px'}}>{this.state.item.title}</a>
                </li>
            );
            if(this.state.item.breadcrumbs !== false) {
                breadcrumb = (
                    <div style={{marginTop:"-3%", marginLeft:"-2%"}}>           
                        <ul className="breadcrumb" >
                            <li className="breadcrumb-item py-3 ps-3">
                                <Link to="/index" style={{textDecoration:"none"}}><i className="feather icon-home" style={{textDecoration:"none", fontSize:'16px'}} title="Dashboard"/></Link>
                            </li>
                            {main}
                            {item}
                        </ul>
                    </div>  
                );
            }
        }

        return (
            <Aux>
                {breadcrumb}
            </Aux>
        );
    }
}

export default Breadcrumb;